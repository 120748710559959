const categorySelector = '#category .category-products';
const higherCategorySelector = '#category .subcategories_block';

globalThis.Convermax.quickview = (item) =>
  window.quickview(`/product.asp?lt_c=1&itemid=${item.CatalogID}&qv=1`);

function InitFunc() {
  const { document } = window;
  if (document.getElementById('cm_results')) {
    document.body.classList.replace('rightbar', 'norightbar');
  }
}

function updateCallback() {
  const { document, location } = window;
  if (document.querySelector(higherCategorySelector) && !document.querySelector(categorySelector)) {
    const showResults = location.hash.length > 1;
    document.body.classList.toggle('cm_show-results', showResults);
  }
}

function getLocalPreselection(pageType, defaults) {
  if (defaults.termFromBreadcrumbs === 'Whats New') {
    return;
  }
  return defaults.getter(pageType);
}

const isClearanceValue = (v) => v.Term.startsWith('Clearance');
function sortCategoryValues({ Values, ...rest }) {
  return {
    ...rest,
    Values: [...Values.filter(isClearanceValue), ...Values.filter((v) => !isClearanceValue(v))],
  };
}
function responseHandler({ Facets, ...rest }) {
  return { ...rest, Facets: Facets.map((f) => (f.FieldName === 'Category' ? sortCategoryValues(f) : f)) };
}

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  defaultView: 'grid',
  disableRequestScroll: true,
  useLocationHash: true,
  getLocalPreselection,
  InitFunc,
  responseHandler,
  SearchRequestDefaults: {
    pageSize: 24,
    queryCorrectionLevel: 'No',
    extra: {
      // eslint-disable-next-line camelcase
      plv_id: btoa(globalThis.__CONVERMAX__.cartpricelevel || ''),
    },
    sort:
      globalThis.document.querySelector('.page_heading')?.innerText === 'Whats New'
        ? 'DateCreated:desc'
        : undefined,
  },
  autocomplete: {
    queryCorrectionLevel: 'No',
    extra: {
      // eslint-disable-next-line camelcase
      plv_id: btoa(globalThis.__CONVERMAX__.cartpricelevel || ''),
    },
  },
  facets: {
    rangedFacet: { fields: ['Price'], step: '5' },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#extrapage',
      template: 'MainContent',
      visibleIf: { selector: '#cm_results' },
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: categorySelector,
      template: 'MainContent',
    },
    {
      name: 'HigherCategoryPage',
      type: 'SearchPage',
      location: { insertBefore: higherCategorySelector },
      visibleIf: () => !window.document.querySelector(categorySelector),
      template: 'MainContent',
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: '#searchGroup .container',
      template: 'MainContent',
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchFacetPanel',
      type: 'FacetPanel',
      location: { replace: '#cm_facets', class: 'clear-self' },
      template: 'FacetPanelContainer',
    },
    {
      name: 'SearchBox',
      location: '#searchBox',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.site-header [data-target="#searchModal"]',
        wrapper: 'a',
        class: 'pull-right',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FilterSearchBox',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'SKU': 'Part Number',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
      },
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
