//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-600:_7448,_9008,_1188,_2416,_3200,_9296,_4256,_4694;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-600')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-600', "_7448,_9008,_1188,_2416,_3200,_9296,_4256,_4694");
        }
      }catch (ex) {
        console.error(ex);
      }