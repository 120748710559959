import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { filterQueryChanged } from 'Core/actions/request.js';
import { searchRequestFilterQuerySelector } from 'Core/selectors/search.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { cloneSafe, preventFormSubmission } from 'Utils/components.ts';

import type { FormEvent, FunctionComponent } from 'react';
import type pageType from 'Addons/search/pageType.ts';
import type { TemplateFunction } from 'Components/types.ts';

type Params = {
  value: string;
  pageType: typeof pageType;
  inputNotEmpty: boolean;
  clearInput: () => void;
  onChange: (value: string) => void;
  onSubmit: (e: FormEvent) => void;
};

type Props = {
  template: TemplateFunction<Params>;
};

const FilterSearchBox: FunctionComponent<Props> = ({ template }) => {
  const dispatch = useDispatch();
  const rootRef = useRef<HTMLElement>(null);

  const [query, setQuery] = useState('');

  const filterQuery = useSelector(searchRequestFilterQuerySelector);

  useEffect(() => {
    setQuery(filterQuery);
  }, [filterQuery]);

  const onChange = (e) => setQuery(e.target.value);

  const onSubmit = (e) => {
    preventFormSubmission(e);
    dispatch(filterQueryChanged(query));
  };

  const clearInput = () => {
    setQuery('');
  };

  const component = template.call({
    value: query,
    pageType: uiConfig.pageType,
    inputNotEmpty: !!query,
    clearInput: () => {
      clearInput();
      rootRef.current?.querySelector('input')?.focus();
    },
    onChange,
    onSubmit,
  });

  return cloneSafe(component, rootRef);
};

export default FilterSearchBox;
