//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-600:_9704,_6952,_6615,_7128,_2782,_7432,_7016,_656;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-600')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-600', "_9704,_6952,_6615,_7128,_2782,_7432,_7016,_656");
        }
      }catch (ex) {
        console.error(ex);
      }